import React from "react";
import { navigate } from "gatsby-link";
import { Col, Row } from 'react-styled-flexboxgrid'
import styled, { createGlobalStyle } from 'styled-components'
import { Button, Header, Section } from '../Elements'
import theme from '../../config/theme'

const Form = styled.form`
  margin-top: 3rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`
const Input = styled.input`
  border: none;
  border-bottom: 1px solid rebeccapurple;
  background-color: rgba(0,0,0,0);
  margin-bottom: 2rem;
  width: 100%;
  outline: none;
  box-shadow: none;
  font-size: 1.4rem;
  font-family: 'IBM Plex Serif';
  font-weight: 300;
`
const Label = styled.label`
  position: absolute;
  top: 6px;
  left: 0px;
  transition: 0.3s;
  font-weight: 300;
`
const PaddedRow = styled(Row)`
  position: relative;
  @media (min-width: ${theme.breakpoints.m}), (min-device-width: ${theme.breakpoints.m}) {
    padding-right: 1.6rem;
  }
`
const TextArea = styled.textarea`
  width: 100%;
  height: 16rem;
  border: 1px solid rebeccapurple;
  border-radius: 3px;
  font-size: 1.2rem;
  font-family: 'IBM Plex Serif';
  font-weight: 300;
  padding: 1rem;
  background-color: rgba(255,255,255,0.4);
`

const LabelStyle = createGlobalStyle`
  input:focus ~ label {
    top: -20px;
    color: rebeccapurple;
    transition: 0.3s
  }
  .input__filled {
    top: -20px;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isValidated: false,
      initClass: {
        email: '',
        name: ''
      }
     };
  }

  componentDidMount = () => {
    this.setState({
      initClass: {
        email: document.getElementById('email_label').className,
        name: document.getElementById('name_label').className
      }
    })
  }
  
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.type === 'email' || e.target.type === 'text') {

      let item = document.getElementById(`${e.target.name + `_label`}`)

      e.target.value.length > 0 ? 
        item.className = this.state.initClass[e.target.name] + " input__filled"
        :
        item.className = this.state.initClass[e.target.name]
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Section id='contact'>
        <LabelStyle />
        <Header title='Contact'/>

        <div dangerouslySetInnerHTML={{ __html: this.props.text.childMarkdownRemark.html }}></div>

        <Form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact"/>
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>

          <Row>
            <Col xs={12} sm={6} md={6}>
              <PaddedRow>
                <Input type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} />
                <Label htmlFor={"name"} id={"name_label"}>Name</Label>
              </PaddedRow>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <PaddedRow>
                <Input type={"email"} name={"email"} onChange={this.handleChange} id={"email"} required={true} />
                <Label htmlFor={"email"} id={"email_label"}>Email</Label>
              </PaddedRow>
            </Col>
          </Row>
          <PaddedRow>
            <Col xs={12}>
              <Row>
                <label htmlFor={"message"}>Message</label>
              </Row>
              <Row>
                <TextArea name={"message"} onChange={this.handleChange} id={"message"} required={true} />
              </Row>
              <Row>
                <Button type="submit">Send Message</Button>
              </Row>
            </Col>
          </PaddedRow>
        </Form>
      </Section>
    )
  }
}
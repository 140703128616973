import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import Headroom from 'react-headroom'

import { Footer, Navigation } from './Sections'
import theme from '../config/theme'
import reset from '../styles/reset'
import 'typeface-ibm-plex-sans'
import 'typeface-ibm-plex-serif'
import 'typeface-nanum-myeongjo'

const GlobalStyle = createGlobalStyle`
  ${reset}
  @font-face {
    font-display: fallback;
    font-family: "SDL";
    font-style: normal;
    font-weight: 400;
    src: local("SDL"), url(${theme.fonts.SDL}) format("woff");
    unicode-range: U+000-5FF;
  }
  html {
    font-family: 'Nanum Myeongjo';
    ${'' /* font-family: "SDL", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif; */}
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'IBM Plex Serif';
    ${'' /* font-family: "SDL", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif; */}
  }
  .nav-active {
    color: ${theme.brand.primary} !important;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Headroom
            upTolerance={10}
            downTolerance={10}
            disable
            style={{
              zIndex: '99',
              WebkitTransition: 'all .5s ease-in-out',
              MozTransition: 'all .5s ease-in-out',
              OTransition: 'all .5s ease-in-out',
              transition: 'all .5s ease-in-out',
              position: 'fixed'
            }}
          >
            <Navigation />
          </Headroom>
          <div
            style={{
              margin: `0 auto`,
              paddingTop: 0,
            }}
          >
            {children}
          <Footer />
          </div>
        </>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
import React from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import theme from '../../config/theme'

import { Container } from '../Elements'

const Wrapper = styled.footer`
  min-height: 4rem;
  margin-top: 4rem;
  @media (max-width: ${theme.breakpoints.m}), (max-device-width: ${theme.breakpoints.m}) {
    margin-bottom: 2.2rem;    
  }
`
const RCol = styled(Col)`
  @media (max-width: ${theme.breakpoints.md}), (max-device-width: ${theme.breakpoints.md}) {
    margin-top: 1rem;
    padding-right: 1.6rem;
  }
  margin-top: 0;
`

const Footer = () => {
  return (
    <Container type='text'>
      <Wrapper>
      <Grid>
        <Row around='sm'>
          <Col xs={12} sm={5} md={5} lg={4}>
            © {new Date().getFullYear()} Mariusz Bachurski
          </Col>
          <RCol xs={12} sm={7} md={7} lg={8}>
            Built with
            {` `}
            <a href="https://www.datocms.com/" target="_blank" rel="noreferrer noopener">DatoCMS</a>
            {`, `}
            <a href="https://www.gatsbyjs.org" target="_blank" rel="noreferrer noopener">Gatsby</a>
            {` and `}
            <a href="https://www.styled-components.com" target="_blank" rel="noreferrer noopener">Styled Components</a>
          </RCol>
        </Row>
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default Footer

import React from 'react'
import withSizes from 'react-sizes'
 
import { Hero } from '../Sections'

const VideoBG = ( { isMobile, props }) => {
  const { heading, subheadingNode, videoBg, videoBgPlaceholder } = props
  return (
     (isMobile) ? 
      <Hero image={ videoBgPlaceholder } text={{ heading, subheadingNode }} />
      :
      <Hero video={ videoBg } text={{ heading, subheadingNode }} />
  )
}
 
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
})
 
export default withSizes(mapSizesToProps)(VideoBG)
import React, { Component, useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { animated, Transition } from 'react-spring/renderprops.cjs'
import { List, ListItem, NavMobile, TechButton, Wrapper } from './styles'

import { Menu } from './NavItems'
import Social from './SocialMedia'

class Mobile extends Component {
  state = { open: undefined }
  toggleMenu = () => this.setState(state => ({ open: !state.open }))

  render() {
    return (
      <Wrapper data-testid="mobile-navigation">
        <Transition
          native
          items={this.state.open}
          from={{ position: 'absolute', overflow: 'hidden', height: 0, top: 0 }}
          enter={[{ height: 'auto' }]}
          leave={{ height: 0 }}
          >
          {open => open && (props => 
            <animated.div style={props}>
              <NavMobile>
                <List>
                  { Menu.map(( item, index ) => {
                    return (
                      <ListItem key={ index }>
                        <TechButton to={ item.path } key={ index } data-testid={item.name} onClick={ this.toggleMenu }>
                          <strong>{ item.name }</strong>
                        </TechButton>
                      </ListItem>
                    )
                  })}
                </List>
              </NavMobile>
            </animated.div>)
          }
        </Transition>
        <FaBars onClick={ this.toggleMenu } style={{ height: '1.5rem', width: '1.5rem', marginLeft: '20px' }} />
        <Social />
      </Wrapper>
    )
  }
}

export default Mobile
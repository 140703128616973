import React, { Component } from 'react';
import { SentryTest } from '../../../components/Elements'

import Desktop from './Desktop'
import Mobile from './Mobile'

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: undefined
    }
  }

  componentDidMount() {
    this.setState({ mounted: true })
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  })

  render() {
    return (
      <div>
        { this.state.windowWidth >= 768
          ? 
          <SentryTest>
            <Desktop>{this.props.children}</Desktop>
          </SentryTest>
          : 
          <SentryTest>
            <Mobile>{this.props.children}</Mobile>
          </SentryTest>
        }
      </div>
    );
  }
}

export default Navigation
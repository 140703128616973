import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';

// Sentry.init({
//  dsn: "https://b33c9a85244d4e069b46250e0577a77e@sentry.io/1437242"
// });
// should have been called before using it here
// ideally before even rendering your react app

class ExampleBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, eventId: null };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({ error })
      Sentry.withScope(scope => {
          scope.setExtras(errorInfo)
          const eventId = Sentry.captureException(error)
          this.setState({eventId})
      });
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
              <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children
        }
    }
}

export default ExampleBoundary
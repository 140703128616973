export const Menu = [{
  name: 'Home',
  path: '/',
},{
  name: 'Portfolio',
  path: '#portfolio',
},
{
  name: 'Technologies',
  path: '#technologies',
},
{
  name: 'About',
  path: '/about',
},
{
  name: 'Contact',
  path: '#contact',
},
]

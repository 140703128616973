import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSection = styled.section`
  padding-top: 32px;
`

const Section = ({ children, id }) => (
  <StyledSection id={id}>
    {children}
  </StyledSection>
)

export default Section

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string
}

Section.defaultProps = {
  id: null,
}
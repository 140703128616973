import React from 'react'
import Img from 'gatsby-image'
import { graphql, Link, StaticQuery } from 'gatsby'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

const Image = styled(Img)`
  box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  :hover  {
    transform: translateX(20px);
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
`
const Wrapper = styled(Grid)`
  margin-top: 3rem;
`
const LinkW = styled(Link)`
  color: #534B62;
  &:hover {
    h3 {
      color: #785589;
    }
  }
`
const Heading = styled.h3`
  margin-bottom: 0.1rem;
  font-size: 1.8rem;
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  letter-spacing: 2px;
`
const Subheading = styled.h5`
  margin-top: 0.4rem;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
`
const Highlight = styled.div`
  background-color: #A499B3;
  width: 28px;
  height: 4px;
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  ${LinkW}:hover & {
    background-color: #98CBB4;
    width: 84px;
    transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
`
const Overlay = styled.div`
  background: #785589;
  opacity: 0.8;
  transition: all 300ms ease-out;
  ${Image}:hover & {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    transform: translateX(-10px);
  }
`;


const WorkItems = () => (
  <StaticQuery
  query={graphql`
    query {
      allDatoCmsPortfolio(sort: {fields: [order], order: ASC} ) {
        edges {
          node {
            heading
            subheading
            screenshot {
              id
              fluid(maxWidth: 500, maxHeight: 200) {
                ...GatsbyDatoCmsFluid
              }
            }
            uid
            order
          }
        }
      }
    }
  `}
  render={data => (
    <Wrapper fluid>
      <Row>
        { data.allDatoCmsPortfolio.edges.map((item, index) => {
          const { heading, screenshot, subheading, uid  } = item.node
          return (
            <Col xs={12} sm={12} md={12} lg={12} key={ index }>
              <LinkW to={uid}>
                <Overlay>
                  <Image fluid={screenshot.fluid} />
                </Overlay>
                <Heading>{ heading }</Heading>
                <Highlight />
                <Subheading>{ subheading }</Subheading>
                
              </LinkW>
            </Col>
          )
        })}
      </Row>
    </Wrapper>
  )
  }
/>
)

export default WorkItems
import { Link } from 'gatsby'
import styled from 'styled-components'

export const Wrapper = styled.header`
  align-items: center;
  display: flex;
  padding: 1rem 0 1rem 0;
  position: relative;
  z-index: 1000;
  background-color: #e8e6f2;
  box-shadow: 3px 4px 30px -3px rgba(0,0,0,0.20);
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    &:hover {
      color: ${props => props.theme.brand.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem 0 1rem 0;
    flex-wrap: wrap;
  }
`

export const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 ${props => props.theme.spacer.horizontal};
  font-size: 1.15rem;
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 2;
  }
`

export const NavMobile = styled.nav`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.bg_color};
  padding: 0 ${props => props.theme.spacer.horizontal};
  font-size: 2rem;
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 2;
  }
`


export const SocialMedia = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 ${props => props.theme.spacer.horizontal};
  a {
    font-size: 1.25rem;
    line-height: 20px;
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 3;
  }
`
export const TechButton = styled(Link)`
  margin: 0 1rem;
  white-space: nowrap;
  text-decoration: none;
  position: relative;
  ${'' /* font-weight: 600; */}
  font-family: 'Noto Serif SC';
  ${'' /* font-style: italic; */}
  font-weight: 400;
  color: #323232;
  transition: 0.2s;
  &:first-child {
    margin-left: 0rem;
  }
  &:hover {
    ::after {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      bottom: 0px;
      background-color: rebeccapurple;
      width: 100%;
      height: 8px;
      z-index: -1;
      opacity: 0.3;
      transition: 0.2s;
    }
  } 
`

export const List = styled.ul`
  list-style: none;
`
export const ListItem = styled.li`
  padding: 1rem 0;
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Header, Section } from '../Elements'
import { TechItems } from '../Parts'

const Content = styled.div`
  a {
    font-family: 'IBM Plex Serif';
    font-style: italic;
    font-weight: 400;
  }
  line-height: 1.6;
  h4 {
    ${'' /* margin-left: 2.8rem; */}
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 400;
  }
`

const Technologies = () => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsTechnology {
          headingMain
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          headingFresh
          freshTech {
            name
            iconString
          }
          headingCore
          coreTech {
            name
            iconString
          }
        }
      }
    `}
    render={data => {
      const { headingMain, headingFresh, headingCore, contentNode, freshTech, coreTech } = data.datoCmsTechnology
      return (
        <Section id='technologies'>
          <Header title={ headingMain } />
          <Content dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}></Content>
          <Header smaller title={ headingFresh } />
          <TechItems items={ freshTech } />
          <Header smaller title={ headingCore } />
          <TechItems items={ coreTech } />
        </Section>
      )
    }
    }
  />
)

export default Technologies
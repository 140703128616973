/* eslint react/display-name: 0 */
import React from 'react'
import { animated, Spring, config } from 'react-spring/renderprops.cjs'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { BGImage, Container, Hero } from '../Elements'

const Content = styled(Container)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  z-index: 3;
`
const Heading = styled.h1`
  margin-bottom: 0.4rem;
  font-family: 'IBM Plex Serif';
  font-size: 3rem;
  font-style: italic;
  font-weight: 300;
`
const SubHeading = styled.div`
  h3 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-family: 'IBM Plex Serif';
    font-style: normal;
    font-weight: 300;
  }
`
const SubHeadingAlt = styled.h3`
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-family: 'IBM Plex Serif';
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 300;
`
const Title = styled(animated.div)`
  margin-top: 0;
`
const Video = styled.video`
  transform: translateY(-400px);
  position: absolute;
  right: 0px;
  @media (max-width: ${props => props.theme.breakpoints.l}),
        (max-device-width: ${props => props.theme.breakpoints.l}) {
    right: -160px;
      
      }
  @media (max-width: ${props => props.theme.breakpoints.m}),
        (max-device-width: ${props => props.theme.breakpoints.m}) {
    right: -400px;
      
      }
`

const HeroComponent = props => {
  const { heading, subheading, subheadingNode } = props.text  
  return (
    <Hero>
      <BGImage>
        { props.video ? 
          <Video id="background-video" loop={true} autoPlay={true} muted={true} style={{  }}>
            <source src={ props.video.url } type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        : <Img fluid={ props.image.fluid } />
        }
      </BGImage>
      <Content type="text">
        <Spring
          native
          config={config.slow}
          from={{ opacity: 0, transform: 'translate3d(0, -30px, 0)' }}
          to={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
        >
          {props => (
            <Title data-testid="project-title" style={props}>
              <Heading>{ heading }</Heading>
              { subheading === undefined && subheadingNode !== undefined ?
                <SubHeading dangerouslySetInnerHTML={{ __html: subheadingNode.childMarkdownRemark.html }}></SubHeading>
                : 
                <SubHeadingAlt>{ subheading }</SubHeadingAlt>
              }
            </Title>
          )}
        </Spring>
      </Content>
    </Hero>
  )
}

export default HeroComponent
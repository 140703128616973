import React from 'react'
import styled from 'styled-components'
import { TechIcons } from '../Elements'

const Item = styled.div`
  padding: 1rem;
`
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
`

const TechItems = props => {  
  return (
    <Wrapper>
      { props.items.map(( item, index ) => (
        <Item key={ index }>
          <TechIcons icon={ item.iconString } />
        </Item>
      ))}
    </Wrapper>
  )
}

export default TechItems
import React, { Component } from 'react'
import { FaGithub, FaGitlab, FaInstagram, FaEnvelope } from 'react-icons/fa'
import { SocialMedia } from './styles'
import { SentryTest } from '../../../components/Elements'

export default class Social extends Component {
  render() {
    return (
      <SentryTest>
        <SocialMedia>
          <a href="https://gitlab.com/mariusz_b" target="_blank" rel="noopener noreferrer" aria-label="Gitlab">
            <FaGitlab />
          </a>
          <a href="https://github.com/embahr" target="_blank" rel="noopener noreferrer" aria-label="Github">
            <FaGithub />
          </a>
          <a href="https://www.instagram.com/mariusz_bachurski" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="mailto:info@mbachurski.com" target="_blank" rel="noopener noreferrer" aria-label="Mail">
            <FaEnvelope />
          </a>
        </SocialMedia>
      </SentryTest>
    )
  }
}

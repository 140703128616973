import React from 'react'
import { Nav, TechButton, Wrapper } from './styles'

import { Menu } from './NavItems'
import Social from './SocialMedia'

const Desktop = () => (
  <Wrapper data-testid="navigation">
    <Nav>
      { Menu.map(( item, index ) => {
        return (
          <TechButton to={ item.path } key={ index } data-testid={item.name}>
           { item.name }
           
          </TechButton>
        )
      })}
    </Nav>
    <Social />
  </Wrapper>
)

export default Desktop
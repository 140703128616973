import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import { FaFilePdf } from 'react-icons/fa'

import { Button } from '../Elements'

const IconWrapper = styled.span`
  padding-right: 0.5rem;
  font-size: 1.25rem;
`

const Resume = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsAboutPage {
        resume {
          url
        }
        resumeText
      } 
    }
  `}
  render={data => {
    const { resume, resumeText } = data.datoCmsAboutPage
    return (
    <div>
      <Button href={ resume.url }>
        <IconWrapper>
          <FaFilePdf />
        </IconWrapper>
        { resumeText }
      </Button>
    </div>
    )
  }
  }
/>
)

export default Resume
import React from 'react'

import { Header, Section } from '../Elements'
import { WorkItems } from '../Parts'

const Portfolio = () => (
  <Section id='portfolio'>
    <Header title='Portfolio'/>
    <WorkItems />
  </Section>
)

export default Portfolio
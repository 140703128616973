import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
  margin-top: 2rem;
  margin-right: ${props => props.spaced ? '1rem' : '0rem' };
  margin-left: ${props => props.spaced ? '1rem' : '0rem' };
  font-family: 'Nanum Myeongjo';
  font-size: 1rem;
  font-weight: 400;
  padding: 0.6rem 1rem;
  min-width: 160px;
  text-decoration: none;
  border: 1px solid rebeccapurple;
  border-radius: 5px;
  background-color: rgba(0,0,0,0);
  transition: 0.3s;
  &:hover {
    border: 1px solid white;
    background-color: rebeccapurple;
    color: white;
    transition: 0.3s;
  }
`

const Button = ({ children, href, spaced, to, type }) => {
  if (href !== undefined) {
    return (
      spaced ? 
        <a href={ href } target="_blank" rel="noreferrer noopener prefetch prerender">
          <StyledButton spaced>
            { children }
          </StyledButton>
        </a>
        :
        <a href={ href } target="_blank" rel="noreferrer noopener prefetch prerender">
          <StyledButton>
            { children }
          </StyledButton>
        </a>
    )
  } else if (to !== undefined) {
    return (
      spaced ? 
        <Link to={ to }>
          <StyledButton spaced>
            { children }
          </StyledButton>
        </Link>
        :
        <Link to={ to }>
          <StyledButton>
            { children }
          </StyledButton>
        </Link>
    )
  } else if (type !== undefined) {
    return (
      spaced ? 
        <StyledButton spaced type={ type }>
          { children }
        </StyledButton>
      :
        <StyledButton type={ type }>
          { children }
        </StyledButton>
    )
  } else {
    return (
      spaced ? 
        <StyledButton spaced>
          { children }
        </StyledButton>
      :
        <StyledButton>
          { children }
        </StyledButton>
    )
  }
}

export default Button

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  spaced: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset']),
}
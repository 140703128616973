import React from 'react'
import { animated, config, Spring } from 'react-spring/renderprops.cjs'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Content = styled.div`
  a {
    font-family: 'IBM Plex Serif';
    font-style: italic;
    font-weight: 400;
  }
  h3 {
    font-family: 'IBM Plex Serif';
    font-style: italic;
    font-weight: 400;
  }
`

const AnimContainer = ({ delay, from, image, text, to }) => {
  return (
    <Spring native config={config.slow} delay={ delay !== undefined ? delay : 400 } 
      from={ from !== undefined ? from : { opacity: 0, transform: 'translate3d(0, 80px, 0)' }}
      to={ to !== undefined ? to : { opacity: 1, transform: 'translate3d(0, 0, 0)' }}>
        { props => (
          <animated.div style={props}>
            { image !== undefined ? 
              <Img fluid={ image } />
              :
              <Content dangerouslySetInnerHTML={{ __html: text }}></Content>
            }
          </animated.div>
        )}
    </Spring>
  )
}

export default AnimContainer
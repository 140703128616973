import React from 'react';
import styled from 'styled-components'

const Highlight = styled.div`
  background-color: rebeccapurple;
  width: 28px;
  height: 4px;
`
const Title = styled.h2`
  font-family: 'IBM Plex Serif';
  font-size: ${props => props.smaller ? '1.4rem' : '2.441rem' }; 
  font-style: italic;
  font-weight: ${props => props.smaller ? 300 : 400 };
  margin-top: ${props => props.smaller ? '1.6rem' : '4rem' };
  margin-bottom: 0px;
  margin-left: ${props => props.smaller ? '2.8rem' : 'inherit' }
`
const Wrapper = styled.div`
  margin-bottom: 2rem;
`

const Header = props => {  
  return (
    <Wrapper>
    { props.smaller ? 
      <Title smaller>{props.title}</Title>
      :
      <Title>{props.title}</Title>
    }
      <Highlight />
    </Wrapper>
  );
};

export default Header;
import React from 'react'
import { graphql } from 'gatsby'
import { Col, Row } from 'react-styled-flexboxgrid'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { Button, Container } from '../components/Elements'
import { AnimContainer } from '../components/Parts'
import { Hero, Resume } from '../components/Sections'

const AboutPage = ({ data }) => {
  const { heading, imageBg, imageFeatured, ramblingNode, ramblingExtendedNode } = data.datoCmsAboutPage
  return (
    <Layout>
      <SEO title="About Page" />
      <Hero image={ imageBg } text={{ heading }} />
      <Container type="text">
        <Row>
          <Col xs={12} sm={6} md={6}>
            <AnimContainer image={ imageFeatured.fluid } from={{ opacity: 0, transform: 'translate3d(-100px, 0, 0)' }} />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <AnimContainer text={ ramblingNode.childMarkdownRemark.html } />
          </Col>
        </Row>
        <AnimContainer text={ ramblingExtendedNode.childMarkdownRemark.html } delay={1000}/>
        <Resume />
        <Button to='/#contact'>
          Send me a message
        </Button>
      </Container>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage {
    datoCmsAboutPage {
      heading
      ramblingNode {
        childMarkdownRemark {
          html
        }
      }
      ramblingExtendedNode {
        childMarkdownRemark {
          html
        }
      }
      imageBg {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      imageFeatured {
        fluid(maxWidth: 300) {
          ...GatsbyDatoCmsFluid
        }
      }
      profileImage {
        fluid(maxWidth: 200) {
          ...GatsbyDatoCmsFluid
        }
      }
    } 
  }
`;